import https from '@/router/https'

// 商品信息表分页列表
export function getGoods(param) {
	return https.fetchGet('/createrapp/storeproductinfo/page', param)
}

// 商品信息表分页列表 店铺首页获取橱窗内商品
export function getShelfGoods(param) {
	return https.fetchGet('/createrapp/storeproductinfo/getShelfPage', param)
}

// 店铺模块详情
export function getModules(param) {
	return https.fetchGet('/createrapp/storeproductinfo/getStoreModules', param)
}

// 店铺基本信息详情
export function getBasic(param) {
	return https.fetchGet('/createrapp/storeproductinfo/getStoreBasic', param)
}

// 商品信息详情查询
export function getProduct(id) {
	return https.fetchGet('/createrapp/storeproductinfo/' + id)
}

// 加入购物车
export function addCart(param) {
	return https.fetchPost('/createrapp/makershoppingcart', param)
}

// 收藏
export function saveCollect(param) {
	return https.fetchPost('/createrapp/usercollect/saveCollect', param)
}

// 收藏
export function cancelCollect(id) {
	return https.fetchDelete('/createrapp/usercollect/' + id)
}

// 获取开店类型
export function getTypes() {
	return https.fetchGet('/createrapp/makerbusinesstype/getmakerbusinesstype')
}
