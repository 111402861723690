<template>
  <div style="background-color: #f6f6f6">
    <van-nav-bar
      :title="categoryName ? categoryName : '分类'"
      left-arrow
      @click-left="$router.back(-1)"
      fixed
      placeholder
    />

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      :immediate-check="false"
    >
      <goods-two :value="goods"></goods-two>
    </van-list>
  </div>
</template>

<script>
import goodsTwo from "@/components/creator-box/goods-two";
import { getGoods } from "@/api/makerStore";
export default {
  name: "makerClassification",
  data() {
    return {
      shopId: null,
      categoryId: null,
      categoryName: null,
      current: 1,
      goods: [],
      total: 0,
      loading: false,
      finished: false,
    };
  },
  components: { goodsTwo },
  created() {
    this.shopId = this.$route.query.shopId;
    this.categoryId = this.$route.query.categoryId;
    this.categoryName = this.$route.query.categoryName;
    this.getGoods();
  },
  computed: {},
  methods: {
    // 获取商品
    getGoods() {
      const obj = {
        shopId: this.shopId,
        current: this.current,
        categoryId: this.categoryId,
      };
      getGoods(obj).then((res) => {
        console.log(res);
        const result = res.data.data;
        this.goods.push(...result.records);

        this.total = result.total;

        this.loading = false;
        if (this.goods.length >= this.total) {
          this.finished = true;
        }
      });
    },
    // 获取商品数据
    onLoad() {
      this.current++;
      this.getGoods();
    },
  },
};
</script>

<style lang="less" scoped>
</style>