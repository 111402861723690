<template>
  <div class="goods-two">
    <div v-for="(item, index) in value" :key="index">
      <div
        class="two-item"
        @click="jumpTo(item)"
        :style="item.images ? '' : 'display: none'"
      >
        <van-image
          width="44vw"
          height="44vw"
          :src="item.images ? item.images[0] : ''"
          style="border-radius: 5px; overflow: hidden"
          fit="cover"
        />
        <div class="name van-multi-ellipsis--l2">
          {{ item.subject }}
        </div>
        <div class="goods-bottom">
          <!-- <div class="shop">洪湖水产专营店</div> -->
          <div class="bottom-bottom">
            <div class="price">
              <span>￥</span>
              <span style="font-size: 18px">{{ item.salePrice }}</span>
            </div>
            <div class="num">
              <span>已售</span>
              <span>{{ item.sales || item.saleNum || "0" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "goodsTwo",
  props: {
    static: {
      default: false,
    },
    value: {
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    jumpTo(item) {
      this.static
        ? null
        : this.$router.push({
            path: "makerGoodsDetail",
            query: { id: item.id },
          });
    },
  },
};
</script>

<style lang="less" scoped>
.goods-two {
  display: flex;
  flex-wrap: wrap;
  .two-item {
    margin: 1vw;
    padding: 2vw;
    width: 44vw;
    background-color: #fff;
    border-radius: 5px;
  }
  .name {
    // margin-bottom: 8px;
    height: 40px;
  }
  .goods-bottom {
    font-size: 12px;
    color: #999;
    .shop {
      margin: 8px 0;
    }
    .bottom-bottom {
      margin: 8px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .price {
        color: #a80000;
      }
    }
  }
}
</style>